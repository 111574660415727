<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1" style="margin-bottom: 10px">
      <div class="pull-right">
        <b-button @click="importFromTeacherPlan" class="btn btn-success" style="margin-right: 10px">Импортировать</b-button>
        <b-button @click="add" class="btn btn-success">{{ $t("common.new") }}</b-button>
      </div>      
    </div>
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">

      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left">
            {{ $t("educationPlanItem.theme") }}
          </th>
          <th class="text-left">
            {{ $t("educationPlanItem.lessonContent") }}
          </th>
          <th class="text-left">
            {{ $t("educationPlanItem.homeWork") }}
          </th>
          <th class="text-left">
            Привязан к уроку
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in planItems" :key="item.id">
          <td>{{ item.theme }}</td>
          <td>{{ item.lessonContent }}</td>
          <td>{{ item.homeWork }}</td>
          <td>{{ item.lessonDateString }}</td>
          <td>
            <b-button
                type="submit"
                @click="editPlanItem(item)"
                variant="primary"
            >
              {{ $t("common.edit") }}
            </b-button>
            <b-button
                type="submit"
                @click="deletePlanItem(item)"
                variant="danger"
                v-if="item.linkedToLesson === false"
            >
              {{ $t("common.delete") }}
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <b-modal ref="subjectEduPlanItem-modal">
      <template v-slot:modal-title>
        {{ $t("common.new") }}
      </template>
      <b-form>
        <b-form-group>
          <template v-slot:label>
            {{ $t("educationPlanItem.theme") }}
          </template>
          <b-form-input
              v-model="form.theme"
              :state="eduPlanItemModelState.theme"
          ></b-form-input>
          <div class="invalid-feedback">{{ eduPlanItemModelSaveError.theme }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("educationPlanItem.lessonContent") }}
          </template>
          <b-form-textarea
              v-model="form.lessonContent"
              :state="eduPlanItemModelState.lessonContent"
          ></b-form-textarea>
          <div class="invalid-feedback">{{ eduPlanItemModelSaveError.lessonContent }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("educationPlanItem.homeWork") }}
          </template>
          <b-form-textarea
              v-model="form.homeWork"
              :state="eduPlanItemModelState.homeWork"
          ></b-form-textarea>
          <div class="invalid-feedback">{{ eduPlanItemModelSaveError.homeWork }}</div>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="onSubmit" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="onReset" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal ref="subjectEduPlanImportModal">
      <div class="row">
        <div class="col-lg-12">
          <b-form-group>
            <template v-slot:label>
              План
            </template>
            <b-form-select
                v-model="selectedPlan"
                :options="plansForImportListSelect"
                @change="planSelected"
            ></b-form-select>
          </b-form-group>
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="text-left">
                <input type="checkbox" v-model="selectAll" @change="selectAllClicked">
              </th>
              <th class="text-left">
                {{ $t("educationPlanItem.theme") }}
              </th>
              <th class="text-left">
                {{ $t("educationPlanItem.lessonContent") }}
              </th>
              <th class="text-left">
                {{ $t("educationPlanItem.homeWork") }}
              </th>              
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in plansForImportItems" :key="item.id">
              <td>
                <input type="checkbox" v-model="item.selected">
              </td>
              <td>{{ item.theme }}</td>
              <td>{{ item.lessonContent }}</td>
              <td>{{ item.homeWork }}</td>              
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
            type="submit"
            @click="importPlanItems"
            variant="primary"
        >
          Импортировать
        </b-button>
        <b-button type="reset" @click="closeImportPlanModal" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import ApiService from "@/common/api.service";

export default {
  name: "SubjectEduPlan",
  components: {},
  props: {
    groupSubjectId: String,
    postponeLoad: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      planItems: [],
      form: {
        theme: "",
        educationHours: "",
        homeWork: ""
      },
      eduPlanItemModelState: {},
      eduPlanItemModelSaveError: {},

      selectedPlan:null,
      plansForImportListSelect:[],
      plansForImportItems:[],
      selectAll:false
    };
  },
  mounted() {
    if(this.postponeLoad === false){
      this.reloadData();
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.subjectId = this.groupSubjectId;

      this.eduPlanItemModelState = {};
      this.eduPlanItemModelSaveError = {};

      ApiService.postSecured("eduPlans/saveItemForSubject", this.form)
          .then(function () {
            $this.$refs["subjectEduPlanItem-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.eduPlanItemModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.eduPlanItemModelState[i] = false;
              }
            }
          });
    },
    add() {
      this.form = {};
      this.$refs["subjectEduPlanItem-modal"].show();
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["subjectEduPlanItem-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("eduPlans/itemsForSubject", {
        params: {subjectId: this.groupSubjectId}
      }).then(({data}) => {
        this.planItems = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    editPlanItem(planItem) {
      this.form = planItem;
      this.$refs["subjectEduPlanItem-modal"].show();
    },
    deletePlanItem(planItem) {
      if(confirm("Удалить?")){
        let $this = this;
        
        ApiService.postSecured("eduPlans/deleteItemForSubject", {id:planItem.id})
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response);
              $this.reloadData();
            });
      }
    },
    importFromTeacherPlan(){
      let $this = this;

      ApiService.querySecured("eduPlans/listForImport", {
        params: {groupSubjectId: this.groupSubjectId}
      }).then(({data}) => {
        $this.plansForImportList = data.list;
        $this.plansForImportListSelect = [];
        for (let ii in data.list){
          let item = data.list[ii];
          $this.plansForImportListSelect.push(
              {
                text:item.name + "// для классов " + item.level + " // предмет " + item.subjectName,
                value:item.id
              }
          ); 
        }
        
        $this.$refs['subjectEduPlanImportModal'].show();
      }).catch(({response}) => {
        console.log(response);
      });
    },
    choosePlan(item){
      let model = {
        groupSubjectId: this.groupSubjectId,
        planId: item.id,
      };
      let $this = this;
      this.$refs['subjectEduPlanImportModal'].hide();
      ApiService.postSecured("eduPlans/import", model)
          .then(function () {
            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();
          });
    },
    closeImportPlanModal(){
      this.$refs['subjectEduPlanImportModal'].hide();
      this.resetSelectData();
    },
    planSelected(planId){
      let $this = this;
      ApiService.querySecured("eduPlans/items", {
        params: {planId: planId}
      }).then(({data}) => {
        $this.plansForImportItems = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    selectAllClicked(){
      if(this.selectAll === true){
        for (let i in this.plansForImportItems){
          this.plansForImportItems[i].selected = true;
        }
      }else{
        for (let i in this.plansForImportItems){
          this.plansForImportItems[i].selected = false;
        }
      }
    },
    importPlanItems(){
      let ids = [];
      for (let i in this.plansForImportItems){
        let item = this.plansForImportItems[i];
        if(item.selected === true){
          ids.push(item.id);
        }
      }
      
      let model = {
        groupSubjectId: this.groupSubjectId,
        itemIds: ids,
      };
      
      let $this = this;
      this.$refs['subjectEduPlanImportModal'].hide();
      ApiService.postSecured("eduPlans/importByItem", model)
          .then(function () {
            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();
          }).finally(function (){
        $this.resetSelectData();
      });
    },
    resetSelectData(){
      this.selectedPlan = null;
      this.plansForImportListSelect = [];
      this.plansForImportItems = [];
      this.selectAll = false;
    }
  }
};
</script>
