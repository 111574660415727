const utilsService = {
  formatDate(dateJson) {
    if(dateJson != null){
      const date = new Date(dateJson);

      return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
    }
   
    return "";
  },
};

export default utilsService;
