import { render, staticRenderFns } from "./SubjectEduPlan.vue?vue&type=template&id=0b06e6f8&"
import script from "./SubjectEduPlan.vue?vue&type=script&lang=js&"
export * from "./SubjectEduPlan.vue?vue&type=script&lang=js&"
import style0 from "./SubjectEduPlan.vue?vue&type=style&index=0&id=0b06e6f8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports